import { createAsyncThunk } from '@reduxjs/toolkit';
import {axiosInstance} from '../../utils/axiosInstance';
import { downloadPDF } from '../../utils/AnalyticsHelper';
import axios from 'axios'
import { getAbortController } from '../../utils/abortControllerUtils';

export const getStatusData = createAsyncThunk('analytic/getStatusData', async ({ filterData, user }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getStatusData');

    const { date, category, state, city } = filterData;

    const response = await axiosInstance.get(`/v1/tenant/statusData?date=${date}&category=${category}&state=${state}&city=${city}`, {
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    });

    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
});

export const getTopIssuesData = createAsyncThunk('analytic/getTopIssuesData', async ({ page, status, date, category, state, city, sortBy, sortOrder, user, inverter, searchQuery,tag='' }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getTopIssuesData');

    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/topIssuesData?page=${page}&status=${status}&date=${date}&category=${category}&state=${state}&city=${city}&inverter=${inverter}&search=${searchQuery}${sortQueryString}&tag=${tag}`, {
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    })

    return res.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
});

export const getDashboardData = createAsyncThunk('analytic/getDashboardData', async ({ status, date, category, state, city, user, inverter, tag = ''}, { rejectWithValue }) => {
  try {

    const currentAbortController = getAbortController('getDashboardData');

    const res = await axiosInstance.get(`/v1/tenant/dashboardData?status=${status}&date=${date}&category=${category}&state=${state}&city=${city}&inverter=${inverter}&tag=${tag}`, {
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    })

    return res.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
});

export const getStatesAndCities = createAsyncThunk('analytic/getStatesAndCities', async (_,{ rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getStatesAndCities');

    const res = await axiosInstance.get(`/v1/tenant/stateAndcities`,{
      signal:currentAbortController.signal
    })
    return res.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
});

export const getLossData = createAsyncThunk('analytic/getLossData', async ({ types = 'losses', status = 'All', date, category, state, city, user, inverter = 'All', tag='' }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getLossData');

    const res = await axiosInstance.get(`/v1/tenant/lossData?type=${types}&status=${status}&date=${date}&category=${category}&state=${state}&city=${city}&inverter=${inverter}&tag=${tag}`, {
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const getSummaryData = createAsyncThunk('analytic/getSummaryData', async ({ page, status, date, category, state, city, sortBy='', sortOrder='', user, inverter, searchQuery, limit,tag='', pageSize = 10, columnFilters = '', selectedColumns = ''}, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getSummaryData');
    const statusString = Array.isArray(status) ? status.join(',') : status;

    const res = await axiosInstance.get(`/v1/tenant/summaryData`, {
      params: {
        page:page+1,
        status:statusString,
        date,
        category,
        state,
        city,
        inverter,
        limit,
        search:searchQuery,
        sortBy,
        sortOrder,
        tag: tag.join(', '),
        pageSize,
        filters: JSON.stringify(
          columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {})
        ),
        selectedColumns: JSON.stringify(selectedColumns.map((col) => col.value)),
      },
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const getInverters = createAsyncThunk('analytic/getInverters', async (_,{ rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/inverterList`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const sendSummaryMail = createAsyncThunk('analytic/sendSummaryMail', async ( data , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY
      }
    }
    const res = await axios.post(`/send_customer_mail`, data, config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const performanceReportData = createAsyncThunk('analytic/performanceReportData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/performanceReport?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const weeklyEnergyData = createAsyncThunk('analytic/weeklyEnergyData', async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/weeklyEnergyData?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const mismatchData = createAsyncThunk('analytic/mismatchData', async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/mismatchData?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const sendSiteInfoMail = createAsyncThunk('analytic/sendSiteInfoMail', async (data , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-host-name': window.location.hostname
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/site-info-mail`, data,config)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const stringInverterLayoutData = createAsyncThunk('analytic/stringInverterLayoutData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/stringInverterLayout?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const froniusInverterLayoutData = createAsyncThunk('analytic/froniusInverterLayoutData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/stringInverterLayout?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const uploadCSVFiles = createAsyncThunk('analytic/uploadCSVFiles', async ( files , { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': process.env.REACT_APP_X_API_KEY
      }
    }
    const res = await axios.post(`/defect_analysis`, files, config)
    return res.data.message
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSolarEdgeData = createAsyncThunk('analytic/getSolarEdgeData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/solarEdgeLayout?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const victronLayoutData = createAsyncThunk('analytic/victronLayoutData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/stringInverterLayout?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSiteSummary = createAsyncThunk('analytic/getSiteSummary', async ({ siteId, date, user }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/siteSummary?siteId=${siteId}&date=${date}`, {
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      }
    })
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getGeoSpatialData = createAsyncThunk('analytic/getGeoSpatialData', async ({ date, category, state, city, inverter, status, search, tag = '' }, { rejectWithValue }) => {
  try {

    const currentAbortController = getAbortController('getGeoSpatialData');

    const res = await axiosInstance.get(`/v1/tenant/geoSpatial?status=${status}&date=${date}&category=${category}&state=${state}&city=${city}&inverter=${inverter}&search=${search}&tag=${tag}`,
      {
        signal:currentAbortController.signal
      }
    )
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const sungrowLayoutData = createAsyncThunk('analytic/sungrowLayoutData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/sungrowLayout?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getInverterParamsKeys = createAsyncThunk('analytic/getInverterParamsKeys', async ( siteId , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/inverterKeys?Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getInverterGraphData = createAsyncThunk('analytic/getInverterGraphData', async ({ date, firstKey, secondKey, device, siteId, index, signal }, { rejectWithValue }) => {
  try {

    const res = await axiosInstance.get(`/v1/tenant/inverterGraphData`,
      { params : {date, firstKey, secondKey, Site_Id : siteId,deviceId:device},
       signal
      })
    return { data: res.data, index }
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const getPowerGraphData = createAsyncThunk('analytic/getPowerGraphData', async ({ date, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/powerGraphData?date=${date}&Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getDeviceIds = createAsyncThunk('analytic/getDeviceIds', async ( siteId , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/deviceIds?Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getHistoricalLogs = createAsyncThunk('analytic/getHistoricalLogs', async ({ siteId, data }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/historical-logs?startDate=${data.startDate}&endDate=${data.endDate}&Site_Id=${siteId}&device=${data.deviceIds}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBatteryDeviceIds = createAsyncThunk('analytic/getBatteryDeviceIds', async ( siteId , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/batteryDeviceIds?Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getUSolarDashboard = createAsyncThunk('analytic/getUSolarDashboard', async ({ date, page }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/uSolar/dashboard?date=${date}&page=${page}`)
    return { data: res.data.data, dashboardBoxData: res.data.dashboardBoxData, totalPages: res.data.totalPages }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBatteryParamsKeys = createAsyncThunk('analytic/getBatteryParamsKeys', async (siteId , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/batteryKeys?Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getUSolarSiteData = createAsyncThunk('analytic/getUSolarSiteData', async ({ date, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/uSolar/site-data?date=${date}&SiteId=${siteId}`)
    return { data: res.data.siteData, boxData: res.data.boxData }
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getActiveGenPrGraphData = createAsyncThunk('analytic/getActiveGenPrGraphData', async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/uSolar/activeGen-vs-pr?startDate=${startDate}&endDate=${endDate}&SiteId=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBatteryGraphData = createAsyncThunk('analytic/getBatteryGraphData', async ({ date, firstKey, secondKey, device, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/batteryGraphData?date=${date}&firstKey=${firstKey}&secondKey=${secondKey}&Site_Id=${siteId}&deviceId=${device}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getPrVsIrradiationData = createAsyncThunk('analytic/getPrVsIrradiationData', async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/uSolar/pr-vs-irradiation?startDate=${startDate}&endDate=${endDate}&SiteId=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBatteryLayoutData = createAsyncThunk('analytic/getBatteryLayoutData', async ({ siteId, date }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/batteryLayoutData?siteId=${siteId}&date=${date}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getProjectDetails = createAsyncThunk('analytic/getProjectDetails', async ( siteId , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/uSolar/project-details?SiteId=${siteId}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTicketHistory = createAsyncThunk('analytic/getTicketHistory', async ( {siteId,page} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/ticket-history?siteId=${siteId}&page=${page}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const generatePdfReport = createAsyncThunk('analytic/generatePdfReport', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/generate-pdf', data, {
      responseType: 'blob', // This tells axiosInstance to expect a Blob response
    })

    downloadPDF(res.data)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const scheduleReport = createAsyncThunk('analytic/scheduleReport', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/schedule-report',data)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveSitePageTemplate = createAsyncThunk('analytic/saveSitePageTemplate', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/sitePageTemplate',data)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSitePageTemplate = createAsyncThunk('analytic/getSitePageTemplate', async (id , { rejectWithValue }) => {

  try {
    let queryString = id ? `?id=${id}` : ''
    const res = await axiosInstance.get(`/v1/tenant/sitePageTemplate${queryString}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getBanners = createAsyncThunk('analytic/getBanners', async (_ , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get('/v1/tenant/banners')
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveCommLossTemplate = createAsyncThunk('analytic/saveCommLossTemplate', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/commLossTemplate',data)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const scheduleCommLossMail = createAsyncThunk('analytic/scheduleCommLossMail', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.post('/v1/tenant/schedule-commLoss',data)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getInverterLogs = createAsyncThunk('analytic/getInverterLogs', async ({siteId,data} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/inverter-logs?startDate=${data.startDate}&endDate=${data.endDate}&Site_Id=${siteId}&device=${data.deviceIds}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getTagCounts = createAsyncThunk('analytic/getTagCounts', async (date , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/tagCounts?date=${date}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const saveSiteMailTempalte = createAsyncThunk('analytic/saveSiteMailTempalte', async (data , { rejectWithValue }) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-host-name': window.location.hostname
      }
    }
    const res = await axiosInstance.post(`/v1/tenant/save-site-mail-template`, data,config)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const savedSiteMailTempalte = createAsyncThunk('analytic/savedSiteMailTempalte', async (data , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/site-mail-template?siteId=${data.siteId}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const savedCommLossTempalte = createAsyncThunk('analytic/savedCommLossTempalte', async (_ , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/commLossTemplate`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getPerformanceMonitoringData = createAsyncThunk('analytic/getPerformanceMonitoringData', async ({siteId, startDate, endDate} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/performanceMonitoring?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getInverterErrorLogs = createAsyncThunk('analytic/getInverterErrorLogs', async ({siteId,data} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/inverter-error-logs?startDate=${data.startDate}&endDate=${data.endDate}&Site_Id=${siteId}&device=${data.deviceIds}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getErrorLogsDeviceIds = createAsyncThunk('analytic/getErrorLogsDeviceIds', async ( siteId , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/error-logs-deviceIds?Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getEnphaseLogs = createAsyncThunk('analytic/getEnphaseLogs', async ({siteId,data} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/enphase-logs?startDate=${data.startDate}&endDate=${data.endDate}&Site_Id=${siteId}&device=${data.deviceIds}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const getBatteryLogs = createAsyncThunk('analytic/getBatteryLogs', async ({siteId,data} , { rejectWithValue }) => {

  try {
    const res = await axiosInstance.get(`/v1/tenant/battery-logs?startDate=${data.startDate}&endDate=${data.endDate}&Site_Id=${siteId}&device=${data.deviceIds}`)
    return res.data
    
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const getSunriseSunsetData = createAsyncThunk('analytic/getSunriseSunsetData', async ({ date, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/sunrise-sunset?date=${date}&Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getRealTimeGraphData = createAsyncThunk('analytic/getRealTimeGraphData', async ({ date, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/real-time-data?date=${date}&Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getSiteEnergyProfileData = createAsyncThunk('analytic/getSiteEnergyProfileData', async ({ date, siteId }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/siteEnergyProfileData?date=${date}&Site_Id=${siteId}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getFaultHistoryData = createAsyncThunk('analytic/getFaultHistoryData', async ({ startDate, endDate, id }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/faultHistory?startDate=${startDate}&endDate=${endDate}&id=${id}`)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})


export const getSitesTableData = createAsyncThunk('analytic/getSitesTableData', async ({ page, status, date, category, state, city, sortBy='', sortOrder='', user, inverter, searchQuery, limit,tag='', pageSize = 10, columnFilters = '', selectedColumns = ''  }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getSitesTableData');

    const res = await axiosInstance.get(`/v1/tenant/sitesTable`, {
      params: {
        page:page+1,
        status,
        date,
        category,
        state,
        city,
        inverter,
        limit,
        search:searchQuery,
        sortBy,
        sortOrder,
        tag: tag.join(', '),
        pageSize,
        filters: JSON.stringify(
          columnFilters.reduce((acc, filter) => {
            acc[filter.id] = filter.value;
            return acc;
          }, {})
        ),
        selectedColumns: JSON.stringify(selectedColumns.map((col) => col.value)),
      },
      headers: {
        'X-User-ID': user._id,
        'X-User-Type': user.userType
      },
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})


export const getProductionConsumptionData = createAsyncThunk('analytic/getProductionConsumptionData', async ({ siteId, startDate, endDate }, { rejectWithValue }) => {
  try {
    const res = await axiosInstance.get(`/v1/tenant/productionConsumptionData?siteId=${siteId}&startDate=${startDate}&endDate=${endDate}`)
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const getNewSitesTableData = createAsyncThunk('analytic/getNewSitesTableData', async ({ startDate, endDate, page, search, sortBy, sortOrder, installationStart, installationEnd }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getNewSitesTableData');

    // const issuesString = selectedIssues.join(',');
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';
    const res = await axiosInstance.get(`/v1/tenant/new-sites-table?startDate=${startDate}&endDate=${endDate}&page=${page}&search=${search}${sortQueryString}&installationStart=${installationStart}&installationEnd=${installationEnd}`,{
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})



export const getNewSitesFlagsData = createAsyncThunk('analytic/getNewSitesFlagsData', async ({ startDate, endDate, page, selectedIssues, search, sortBy, sortOrder, installationStart, installationEnd }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getNewSitesFlagsData');

    const issuesString = selectedIssues.join(',');
    const sortQueryString = sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : '';

    const res = await axiosInstance.get(`/v1/tenant/new-sites-flags?startDate=${startDate}&endDate=${endDate}&page=${page}&issues=${issuesString}&search=${search}${sortQueryString}&installationStart=${installationStart}&installationEnd=${installationEnd}`,{
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const getCompleteFlagsData = createAsyncThunk('analytic/getCompleteFlagsData', async ({ startDate, endDate, siteId, installationStart, installationEnd }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getCompleteFlagsData');

    // const issuesString = selectedIssues.join(',');
    const res = await axiosInstance.get(`/v1/tenant/sites-flags-extended?startDate=${startDate}&endDate=${endDate}&siteId=${siteId}&installationStart=${installationStart}&installationEnd=${installationEnd}`,{
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})


export const getNewSites = createAsyncThunk('analytic/getNewSites', async ({installationStart, installationEnd}, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getNewSites');

    // const issuesString = selectedIssues.join(',');

    const res = await axiosInstance.get(`/v1/tenant/new-sites?installationStart=${installationStart}&installationEnd=${installationEnd}`,{
      signal: currentAbortController.signal
    })
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})

export const setSiteConfirmed = createAsyncThunk('analytic/setSiteConfirmed', async ( data , { rejectWithValue }) => {
  try {
    const res = await axiosInstance.put(`/v1/tenant/confirmSite`, data)
    return res.data
  } catch (error) {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
})

export const getNewSitesDashboardData = createAsyncThunk('analytic/getNewSitesDashboardData', async ({ date, installationStart, installationEnd }, { rejectWithValue }) => {
  try {
    const currentAbortController = getAbortController('getNewSitesDashboardData');

    const res = await axiosInstance.get(`/v1/tenant/new-sites-dashboard?date=${date}&installationStart=${installationStart}&installationEnd=${installationEnd}`,{
      signal: currentAbortController.signal
    })
    
    return res.data
  } catch (error) {
    if (axios.isCancel(error)) {
      // Request was cancelled
      // console.log('Request cancelled', error.message);
    } else {
    return rejectWithValue(
      error.response && error.response.data && error.response.data.msg
        ? error.response.data.msg : error.response.data.error ? error.response.data.error
          : 'An error occurred'
    );
  }
  }
})