import html2canvas from 'html2canvas';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet'
import iconMarker from 'leaflet/dist/images/marker-icon.png'
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import Papa from 'papaparse';
import TrendChart from '../components/analytics/TopIssues/TrendChart';


export const getCategory = (type) => {

  let categories = []
  if (type === 'losses') {
    categories = [
      'a. < -30%',
      'b. > -30%',
      'c. > -20%',
      'd. > -10%',
      'e. > -5%',
      // 'f. > 0%'
    ]
  } else if (type === 'shading') {
    categories = [
      ' | evening',
      ' | morning',
      ' | morning | evening',
      'nd',
      'no shading'
    ]
  } else if (type === 'soiling') {
    categories = ['b. < 10%', 'c. < 5%', 'd. < 3%', 'e. < 2%', 'f. < 1%']
  } else if (type === 'mismatch') {
    categories = [
      'a. < -30%',
      'b. > -30%',
      'c. > -20%',
      'd. > -10%',
      'e. > -5%',
      'f. > -3%'
    ]
  }
  return categories
}


export const getColorForCategory = (category, type) => {
  const colorMaps = {
    losses: {
      'a. < -30%': '#A50026',
      'b. > -30%': '#F46D43',
      'c. > -20%': '#FEE08B',
      'd. > -10%': '#D9EF8B',
      'e. > -5%': '#66BD63',
      'f. > 0%': '#006837',
    },
    shading: {
      ' | evening': '#1B1B1B',
      ' | morning': '#484848',
      ' | morning | evening': '#7C7C7C',
      'nd': '#B4B4B4',
      'no shading': '#c8c8c8',
    },
    soiling: {
      'b. < 10%': '#A50026',
      'c. < 5%': '#FEE08B',
      'd. < 3%': '#FDBE6E',
      'e. < 2%': '#B7E075',
      'f. < 1%': '#006837',
    },
    mismatch: {
      'a. < -30%': '#A50026',
      'b. > -30%': '#F46D43',
      'c. > -20%': '#FEE08B',
      'd. > -10%': '#D9EF8B',
      'e. > -5%': '#66BD63',
      'f. > -3%': '#006837',
    },
  };

  const colorMap = colorMaps[type] || {};
  return colorMap[category] || '#000000';
}

export const mailAvailableSiteIds = [2767616,
  3549426,
  3553335,
  1907450,
  1887037,
  1929644,
  2169082,
  2303945,
  2743984,
  2758706,
  3157646,
  3280727,
  3381664,
  1524747,
  1750416,
  1909934,
  2073146,
  2275322,
  2811878,
  1987375,
  2604929,
  3374876,
  1658579,
  2466457,
  3201898,
  2752360,
  2856535,
  3171511,
  2787274,
  2863344,
  2130282,
  2409943,
  2609489,
  2767616,
  3549426,
  3553335
]

export const getColorByValue = (value) => {
  const saturation = 100
  const lightness = 50

  let hue

  if (value <= 0 && value >= -5) {
    hue = 60 + value * 2
  } else if (value < -5 && value >= -10) {
    hue = 50 + (value * 2 + 4)
  } else if (value < -10 && value >= -15) {
    hue = 30 + (value + 6)
  } else if (value < -15 && value >= -20) {
    hue = 15 + (value + 8)
  } else {
    hue = 0
  }

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}


export const captureChartAsImage = async () => {

  const chartElement = document.querySelector('.recharts-responsive-container')

  const canvas = await html2canvas(chartElement)
  const chartImageURL = canvas.toDataURL('image/png')


  return chartImageURL
}

export const getButtonBackgroundColor = (selectedButton, name) => {
  if (selectedButton === name) {
    return '#ffd232';
  }

  if (
    (selectedButton === 'maxTemp' || selectedButton === 'meanTemp') &&
    name === 'Temperature'
  ) {
    return '#ffd232';
  }

  if (
    (selectedButton === 'minResistance' ||
      selectedButton === 'meanResistance') &&
    name === 'Ground Fault Resistance'
  ) {
    return '#ffd232';
  }

  if (
    (selectedButton === 'minResistance' ||
      selectedButton === 'meanResistance') &&
    name === 'Resistance'
  ) {
    return '#ffd232';
  }

  if (
    (selectedButton === 'chargingResistancePerkWh' ||
      selectedButton === 'dischargingResistancePerkWh') &&
    name === 'Internal Resistance'
  ) {
    return '#ffd232';
  }

  if (
    (selectedButton === 'tempMin' ||
      selectedButton === 'tempMax' ||
      selectedButton === 'tempMean') &&
    name === 'Temperature'
  ) {
    return '#ffd232';
  }

  return 'white';
};


export const generateCustomMarkerIcon = (status) => {
  const getColorBasedOnStatus = (status) => {
    switch (status) {
      case 'Healthy':
        return '#388E3C';
      case 'Unhealthy':
        return '#f57c00';
      case 'Production Issue':
        return '#FF0000';
      case 'Comm Loss':
        return '#f5b92c';
      case 'Curtailment':
        return '#8A3324';
      default:
        return 'black'; // Default color for unknown statuses
    }
  };
  const color = getColorBasedOnStatus(status);

  // if(color === 'orange'){
  //   return  L.icon({
  //       iconRetinaUrl: iconRetina,
  //       iconUrl: iconMarker,
  //       shadowUrl: iconShadow,
  //       iconSize: [30, 46],
  //       iconAnchor: [17, 46],
  //     })
  // }

  const iconMarkup = renderToStaticMarkup(
    <div style={{ position: 'relative' }}>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        size='4x'
        style={{ color: color }}
      // className={` text-darken-1`}
      />
      <div
        style={{
          position: 'absolute',
          top: '40%',
          left: '135%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          width: '15px',
          height: '15px',
          backgroundColor: 'white',
          zIndex: 1,
        }}
      >
        <FontAwesomeIcon
          icon={faCircle}
          size='sm'
          style={{ color: 'white', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
      </div>
    </div>
  );


  return L.divIcon({
    html: iconMarkup,
    iconAnchor: [17, 46],
    className: 'dummy',
  });
};

export const generateTicks = (data, state, sunriseSunsetData, type) => {
  let MILLISECONDS_IN_HOUR = 3600000
  if(type=== 'realtime'){
    MILLISECONDS_IN_HOUR = MILLISECONDS_IN_HOUR/1000
  }
  let startTime
  let endTime
  if (typeof state.left !== 'number' && data.length > 0) {
    if (sunriseSunsetData && sunriseSunsetData.sunriseTimestamp && sunriseSunsetData.sunsetTimestamp && type !== 'inverter') {
      startTime = sunriseSunsetData.sunriseTimestamp - MILLISECONDS_IN_HOUR;
      endTime = sunriseSunsetData.sunsetTimestamp + MILLISECONDS_IN_HOUR;
    } else if(type === 'inverter') {
      startTime = data[0].timestamp
      endTime = data[data.length - 1].timestamp
    }else if(type === 'realtime'){
      startTime = data[0].ts
      endTime = data[data.length - 1].ts
    }
  } else {
    startTime = state.left
    endTime = state.right
  }

  let interval = 30 * 60 * 1000 // 30 minutes in milliseconds

  if(type === 'realtime'){
    interval = interval / 1000
  }
  const tickIntervals = []



  // if (endTime - startTime < interval) {
  //   tickIntervals.push(startTime, endTime);
  // } else {
  //   for (let time = startTime; time <= endTime; time += interval) {
  //     tickIntervals.push(time);
  //   }
  // }

  // if(tickIntervals.includes(endTime)){
  //   return tickIntervals;
  // }else{
  //   tickIntervals.push(endTime)
  //   return tickIntervals;
  // }

  if (sunriseSunsetData && type !== 'inverter') {
    if (sunriseSunsetData.sunriseTimestamp >= startTime && sunriseSunsetData.sunriseTimestamp <= endTime && !tickIntervals.includes(sunriseSunsetData.sunriseTimestamp)) {
      tickIntervals.push(sunriseSunsetData.sunriseTimestamp);
    }
    if (sunriseSunsetData.sunsetTimestamp >= startTime && sunriseSunsetData.sunsetTimestamp <= endTime && !tickIntervals.includes(sunriseSunsetData.sunsetTimestamp)) {
      tickIntervals.push(sunriseSunsetData.sunsetTimestamp);
    }

  }

  if (endTime - startTime < interval) {
    if (!tickIntervals.includes(startTime)) tickIntervals.push(startTime);
    if (!tickIntervals.includes(endTime)) tickIntervals.push(endTime);
  } else {
    for (let time = startTime; time <= endTime; time += interval) {
      if (!tickIntervals.includes(time)) {
        tickIntervals.push(time);
      }
    }
    if (!tickIntervals.includes(endTime)) {
      tickIntervals.push(endTime);
    }
  }
  // Sort tick intervals to ensure correct order
  tickIntervals.sort((a, b) => a - b);

  return tickIntervals;


}

export const getPreviousDate = (days) => {
  const oneDay = 24 * 60 * 60 * 1000;
  const today = new Date();
  return new Date(today.getTime() - oneDay * days);
};

export const captureScreenshot = async (section) => {
  const element = document.querySelector(`.${section}`);
  const canvas = await html2canvas(element);

  return canvas.toDataURL('image/png');
};

export const generatePDF = async (selectedSections) => {
  let data = {}
  for (const section in selectedSections) {
    if (selectedSections[section]) {
      const screenshot = await captureScreenshot(section);
      data[section] = screenshot
    }
  }
  return data
  // const content = document.querySelector('.content')

  // pdf.fromHTML(content,15,15)
  // pdf.save('geenn.pdf')

  // pdf.save('report.pdf');

};

export const downloadPDF = (pdfData, fileName = 'report.pdf') => {
  const blob = new Blob([pdfData])
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = fileName
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const exportToCSV = (csvData) => {
  const csvString = Papa.unparse(csvData);
  const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(csvBlob);
}

export const isValidEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};


export const isTempValid = (data) => {
  const tempValid = data?.some(
    item =>
      (item.tempMax !== null &&
        item.tempMax !== undefined &&
        item.tempMax !== 0) ||
      (item.tempMean !== null &&
        item.tempMean !== undefined &&
        item.tempMean !== 0)
  )
  return tempValid
}

export const isResistanceValid = (data, type) => {
  let resistanceValid
  if (type === 'stringInverter') {
    resistanceValid = data?.some(
      item =>
        item.resMin !== null &&
        item.resMin !== undefined &&
        item.resMean !== null &&
        item.resMean !== undefined &&
        item.resMean !== 0
    )
  } else if (type === 'sungrow') {
    resistanceValid = data?.some(
      item =>
        item.irMin !== null &&
        item.irMin !== undefined &&
        item.irMean !== null &&
        item.irMean !== undefined &&
        item.irMean !== 0
    )
  } else {
    resistanceValid = data?.some(
      item =>
        item.gfrMin !== null &&
        item.gfrMin !== undefined &&
        item.gfrMean !== null &&
        item.gfrMean !== undefined &&
        item.gfrMean !== 0
    )
  }

  return resistanceValid
}

export const isMismatchValid = (data) => {
  let mismatchValid = (data || [])
    .some(item =>
      item.mismatch !== null &&
      item.mismatch !== undefined
    )


  return mismatchValid
}

export const isEfficiencyValid = (data) => {
  let efficiencyValid = (data || [])
    .some(item =>
      item.efficiency !== null &&
      item.efficiency !== undefined
    )


  return efficiencyValid
}

export const isLateStartTimeValid = (data) => {
  let lateStartTimeValid = (data || [])
    .some(item =>
      item.lateStartTime !== null &&
      item.lateStartTime !== undefined
    )


  return lateStartTimeValid
}

export const isEarlyShutTimeValid = (data) => {
  let earlyShutTimeValid = (data || [])
    .some(item =>
      item.earlyShutTime !== null &&
      item.earlyShutTime !== undefined
    )


  return earlyShutTimeValid
}


export const getColorByTemp = (value, type) => {
  let saturation = 100
  let lightness = 50

  let hue
  if (type === 'max') {
    if (value > 70) {
      hue = 0
    } else if (value >= 66 && value <= 70) {
      hue = value - 2
    } else if (value < 66) {
      hue = 123
      saturation = 43
      lightness = 39
    }
  } else if (type === 'mean') {
    if (value > 60) {
      hue = 0
    } else if (value >= 55 && value <= 60) {
      hue = value + 4
    } else if (value < 55) {
      hue = 123
      saturation = 43
      lightness = 39

    }
  }

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export const getColorByResistance = (value, type) => {
  let saturation = 100
  let lightness = 50

  let hue

  if (type === 'min') {
    if (value < 800) {
      hue = 0
    } else if (value >= 800 && value <= 2000) {
      hue = 62
    } else if (value > 2000) {
      hue = 123
      saturation = 43
      lightness = 39
    }
  } else if (type === 'mean') {
    if (value < 1200) {
      hue = 0
    } else if (value >= 1200 && value <= 3000) {
      hue = 62
    } else if (value > 3000) {
      hue = 123
      saturation = 43
      lightness = 39
    }
  }

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}


export const processInverterLogsData = (data, flag) => {
  const excludedMetrics = [
    "batteryCapacity",
    "batteryChargingEnergyToday",
    "batteryDischargingEnergyToday",
    "batteryServiceableRatio",
    "chargingPerc",
    "discharhingPerc",
    "productionByCapacity",
    "socChangeDischarging",
    "socChangeCharging",
    "socMax",
    "socMin",
    "socRange",
    "reportingIssueFlag"
  ];
  const rows = data.metrics.filter(metric => !excludedMetrics.includes(metric.name)).map(metric => {
    let totalValue = 0;
    let validEntriesCount = 0;
    let hasValidValue = false;

    metric.values.forEach(entry => {
      if (entry[flag] !== 'N/A' && entry[flag] !== null && entry[flag] !== undefined) {
        totalValue += entry[flag];
        validEntriesCount++;
        hasValidValue = true;
      }
    });

    const values = metric.values?.map(data => data.value)
    let finalValue = metric.values[0] && metric.values[0].aggFunc === 'mean'
      ? (validEntriesCount > 0 ? totalValue / validEntriesCount : 'N/A')
      : metric.values[0].aggFunc === 'min' ? Math.min(...values) : metric.values[0].aggFunc === 'max' ? metric.name === 'mismatchMax' ? Math.min(...values) :  Math.max(...values) : metric.values[0].aggFunc === 'mode' ? findMode(values) : totalValue;

    if (metric.name === "theoreticalSoh" || metric.name === "soh") {
        finalValue = finalValue !== 'N/A' ? finalValue * 100 : finalValue;
      }

    return hasValidValue ? ({
      name: metric.name,
      values: data.dates.map(date => {
        const entry = metric.values.find(v => v.date === date);
        let value = entry && entry[flag] ? entry[flag] : 0;
        if (metric.name === "theoreticalSoh" || metric.name === "soh") {
          value = value * 100;
        }
        return value;
      }),
      finalValue: finalValue !== 'N/A' && flag !== 'timeWindows' ? metric.name === 'lowPerfCount' ? Math.round(finalValue) : parseFloat(finalValue)?.toFixed(2) : 0
    }) : null;
  }).filter(row => row !== null);

  return {
    rowsWithValidData: rows?.sort((a, b) => a.name.localeCompare(b.name)),
    hasValidRows: rows.length > 0
  };
}


export const formatDate = dateString => {
  if (!dateString) {
    const currentDate = new Date()
    const firstDateOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    )

    // return firstDateOfMonth.toLocaleDateString('en-CA')
    return ''
  }
  if (dateString !== '' && typeof dateString === 'string') {
    const formattedDate = new Date(dateString).toISOString().split('T')[0]
    return formattedDate
  } else if (typeof dateString === 'number') {
    const date = new Date(dateString)
    const futureDate = date.getDate()
    date.setDate(futureDate)
    return date.toLocaleDateString('en-CA')
  }
}

export const getSectionName = (component, inverter) => {
  let sungrowSectionName = 'Sungrow/Goodwe Layout'
  let stringInverterSectionName = 'Havells/Growatt Layout'
  if (inverter === 'Sungrow' || inverter === 'Goodwe') {
    sungrowSectionName = inverter
  }
  if (inverter === 'Havells' || inverter === 'Growatt') {
    stringInverterSectionName = inverter
  }
  const sections = {
    'header': 'Header',
    'siteDetailsSection': 'Site Details',
    'performanceMonitoringSection': 'Performance Monitoring',
    'energyGraphSection': 'Weekly Energy Graph',
    'mismatchLossSection': 'Mismatch Loss',
    'stringInverterSection': stringInverterSectionName,
    'solarEdgeSection': 'SolarEdge Layout',
    'victronSection': 'Victron Layout',
    'sungrowSection': sungrowSectionName,
    'froniusSection': 'Fronius Layout',
    'batteryLayoutSection': 'Battery Layout',
    'powerGraphSection': 'Power Graph',
    'inverterGraphSection': 'Inverter Graph',
    'batteryGraphSection': 'Battery Graph',
    'HistoricalLogsSection': 'Inverter Logs',
    'ticketHistory': 'Ticket History'
  }

  return sections[component] || component
}

export const getIconColor = (value, index) => {
  switch (value) {
    case 'Top 10%':
      return index < 1 ? 'tw-text-green-500' : 'tw-text-red-500';
    case 'Top 25%':
      if (index < 2) return 'tw-text-green-500';
      if (index === 2) return 'tw-text-[#839900]'; // Custom class for half-colored icon
      return 'tw-text-red-500';
    case 'Top 50%':
      return index < 5 ? 'tw-text-green-500' : 'tw-text-red-500';
    case 'Bottom 50%':
      return index >= 5 ? 'tw-text-green-500' : 'tw-text-red-500';
    case 'Bottom 25%':
      if (index > 7) return 'tw-text-green-500';
      if (index === 7) return 'tw-text-[#986707]'; // Custom class for half-colored icon
      return 'tw-text-red-500';
    case 'Bottom 10%':
      return index > 8 ? 'tw-text-green-500' : 'tw-text-red-500';
    default:
      return 'tw-text-red-500';
  }
}

export const getDaysDifference = (dateString,status,closedDate) => {
  const date = new Date(dateString);
  const endDate = status === 'closed' ? new Date(closedDate) : new Date();

  const differenceInMilliseconds = endDate - date;

  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
  return differenceInDays
}

export const getHoursDifference = (dateString,status,closedDate) => {
  const date = new Date(dateString);
  const endDate = status === 'closed' ? new Date(closedDate) : new Date();

  const differenceInMilliseconds = endDate - date;

  const differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

  return differenceInHours;
};

// Combine both functions into one
export const getDifference = (dateString,status,dor) => {
  const daysDifference = getDaysDifference(dateString,status,dor);

  if (daysDifference >= 1) {
    return Math.floor(daysDifference);
  } else {
    return getHoursDifference(dateString,status,dor)
  }
};

export const getShadowColor = (tag, value, type) => {
  if (tag === 'Efficiency') {
    if (type === 'inverter') {
      switch (true) {
        case value > 99:
          return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
        case value > 96 && value < 99:
          return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
        case value > 93 && value < 97:
          return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
        case value > 91 && value < 94:
          return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
        case value > 88 && value < 92:
          return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
        case value > 83 && value < 89:
          return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
        case value > 78 && value < 84:
          return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
        case value < 79:
          return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
        default:
          return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      }
    } else {
      switch (true) {
        case value > 95:
          return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
        case value > 93 && value < 96:
          return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
        case value > 90 && value < 94:
          return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
        case value > 88 && value < 91:
          return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
        case value > 85 && value < 89:
          return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
        case value > 80 && value < 86:
          return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
        case value > 75 && value < 81:
          return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
        case value < 76:
          return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
        default:
          return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      }
    }

  } else if (tag === 'SOH') {
    switch (true) {
      case value > 99:
        return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
      case value > 98 && value < 100:
        return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
      case value > 97 && value < 99:
        return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
      case value > 96 && value < 98:
        return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
      case value > 95 && value < 97:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value > 94 && value < 96:
        return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
      case value > 93 && value < 95:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value < 94:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      default:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
    }
  } else if (tag === 'tempMin') {
    switch (true) {
      case value < 21:
        return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
      case value > 20 && value < 26:
        return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
      case value > 25 && value < 28:
        return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
      case value > 28 && value < 31:
        return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
      case value > 30 && value < 33:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value > 32 && value < 35:
        return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
      case value > 34 && value < 40:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value >= 40:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      default:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
    }
  } else if (tag === 'tempMax') {
    switch (true) {
      case value < 25:
        return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
      case value >= 25 && value < 27:
        return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
      case value >= 27 && value < 30:
        return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
      case value >= 30 && value < 35:
        return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
      case value >= 35 && value < 38:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value >= 38 && value < 40:
        return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
      case value >= 40 && value < 45:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value >= 45:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      default:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
    }
  } else if (tag === 'tempMean') {
    switch (true) {
      case value < 20:
        return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
      case value > 15 && value <= 20:
        return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
      case value > 10 && value <= 15:
        return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
      case value > 5 && value <= 10:
        return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
      case value > 0 && value <= 5:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value > -5 && value <= 0:
        return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
      case value > -10 && value <= -5:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value <= -10:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      default:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
    }
  } else if (tag === 'chargingResistancePerkWh' || tag === 'dischargingResistancePerkWh') {
    switch (true) {
      case value < 100:
        return "inset 0 0 10px 5px rgba(0, 100, 0, 1)";
      case value < 150 && value >= 100:
        return "inset 0 0 10px 5px rgba(144, 238, 144, 0.7)";
      case value < 200 && value >= 150:
        return "inset 0 0 10px 5px rgba(255, 255, 224, 0.7)";
      case value < 250 && value >= 200:
        return "inset 0 0 10px 5px rgba(255, 215, 0, 0.7)";
      case value < 350 && value > 250:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value < 400 && value >= 350:
        return "inset 0 0 10px 5px rgba(255, 140, 0, 0.7)";
      case value < 600 && value >= 400:
        return "inset 0 0 10px 5px rgba(255, 160, 122, 0.7)";
      case value >= 600:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
      default:
        return "inset 0 0 10px 5px rgba(139, 0, 0, 0.7)";
    }
  }
}


const inverterTagsColorRanges = {
  "faultAlarm": {
    thresholds: [0, 0.2, 0.5, 1],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "gfError": {
    thresholds: [0, 0.25, 0.5, 1, 1.5, 2, 2.5],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "gfrMin": {
    thresholds: [6000, 4000, 3000, 2000, 1500, 1000, 800],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "gridUptime": {
    thresholds: [100, 99, 98, 97, 96, 95, 90],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "highVoltageError": {
    thresholds: [0, 0.25, 0.5, 1, 1.5, 2, 2.5],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "irMin": {
    thresholds: [8000, 6000, 4000, 3000, 2000, 1500, 1000],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "lowVoltageError": {
    thresholds: [0, 0.25, 0.5, 1, 1.5, 2, 2.5],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "mismatchMax": {
    thresholds: [-0.5, -2.5, -5, -7.5, -10, -15, -20],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "mismatchMean": {
    thresholds: [-0.2, -2, -4, -6, -8, -10, -12],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "offHours": {
    thresholds: [0, 0.2, 0.5, 1],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "tempDeration": {
    thresholds: [0, 0.25, 0.5, 1, 1.5, 2, 2.5],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "tempMax": {
    thresholds: [45, 50, 55, 60, 65, 70, 75],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "throttledHours": {
    thresholds: [0.2, 0.5, 1, 1.5, 2, 2.5, 3],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "curtailedHours": {
    thresholds: [0, 0.2, 0.5, 1, 1.25, 1.5, 2],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)"]
  },
  "efficiency": {
    thresholds: [99, 98, 96, 93, 91, 88, 83, 78],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "batteryFault": {
    thresholds: [0],
    colors: ["rgba(255, 100, 100, 0.7)"]
  }
};

const BatteryColorRanges = {
  "chargingEfficiency": {
    thresholds: [98, 95, 93, 90, 88, 85, 80, 75],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "chargingResistancePerkWh": {
    thresholds: [50, 100, 150, 200, 250, 350, 400, 600],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "dischargingEfficiency": {
    thresholds: [98, 95, 93, 90, 88, 85, 80, 75],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "dischargingResistancePerkWh": {
    thresholds: [50, 100, 150, 200, 250, 350, 400, 600],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "efficiency": {
    thresholds: [98, 95, 93, 90, 88, 85, 80, 600],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "soh": {
    thresholds: [100, 99, 98, 97, 96, 95, 94, 93],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "tempMax": {
    thresholds: [20, 25, 27, 30, 35, 38, 40, 45],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "tempMean": {
    thresholds: [20, 25, 27, 28, 30, 33, 35, 40],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "tempMin": {
    thresholds: [25, 20, 15, 10, 5, 0, -5, -10],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  },
  "maxVoltageRange": {
    thresholds: [50, 100, 150, 170, 200, 250, 300, 500],
    colors: ["rgba(0, 100, 0, 0.7)", "rgba(100, 200, 100, 0.7)", "rgba(255, 255, 200, 0.7)", "rgba(255, 255, 0, 0.7)", "rgba(255, 200, 100, 0.7)", "rgba(255, 165, 0, 0.7)", "rgba(255, 100, 100, 0.7)", "rgba(255, 0, 0, 0.7)"]
  }
};

export const getColorForInverterTagValue = (tagName, value, type) => {
  let tag
  if (type === 'battery') {
    tag = BatteryColorRanges[tagName];
  } else {
    tag = inverterTagsColorRanges[tagName];
  }

  if (!tag) {
    return 'rgba(255, 255, 255, 0)';
  }

  const { thresholds, colors } = tag;
  const isAscending = thresholds.length > 1 && thresholds[1] > thresholds[0];

  for (let i = 0; i < thresholds.length; i++) {
    if ((isAscending && value <= thresholds[i]) || (!isAscending && value >= thresholds[i])) {
      return colors[i];
    }
  }

  return colors[colors.length - 1]
}

export const findMode =  (arr) => {
  // Step 1: Create a frequency map using reduce
  const frequency = arr.reduce((acc, num) => {
    acc[num] = (acc[num] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Find the entry with the highest frequency
  const mode = Object.entries(frequency).reduce((acc, [num, count]) => {
    if (count > acc[1]) {
      return [num, count];
    }
    return acc;
  }, [null, 0]);

  // Return the mode
  return mode[0];
}


export const getSiteColumns = () => {
  const columnsConfig = [
    { value: 'systemName', label: 'Site Name', header: 'Site Name' },
    { value: 'city', label: 'City', header: 'City' },
    { value: 'status', label: 'Status', header: 'Status' },
    { value: 'inverter', label: 'Inverter', header: 'Inverter' },
    { value: 'kWh/kWp', label: 'kWh/kWp', header: 'kWh/kWp', filterVariant: 'range' },
    {
      value: 'kWpCapacity',
      label: 'kWp Capacity',
      header: 'kWp Capacity',
      filterVariant: 'range'
    },
    {
      value: 'rating',
      label: 'Rating',
      header: 'Rating',
      filterVariant: 'select'
    },
    { value: 'sps', label: 'SPS', header: 'SPS', filterVariant: 'range' },
    { value: 'alertLevel', label: 'Alert', header: 'Alert' },
    { value: 'workmanship', label: 'Workmanship', header: 'Workmanship', filterVariant: 'select' },
    { value: 'Trend', label: 'Trend', header: 'Trend' },
    { value: 'batteryCapacity', label: 'Battery Capacity', header: 'Battery Capacity', filterVariant: 'range' },
    { value: 'rank', label: 'Rank', header: 'Rank', filterVariant: 'range' },
    { value: 'inverterEff', label: 'Inverter Efficiency', header: 'Inverter Efficiency', filterVariant: 'range' },
    { value: 'batteryEff', label: 'Battery Efficiency', header: 'Battery Efficiency', filterVariant: 'range' },
    { value: 'weightedBatteryEff', label: 'Weighted Battery Efficiency', header: 'Weighted Battery Efficiency', filterVariant: 'range' },
    { value: 'weightedInverterEff', label: 'Weighted Inverter Efficiency', header: 'Weighted Inverter Efficiency', filterVariant: 'range' },
    { value: 'socMin', label: 'SOC Min', header: 'SOC Min', filterVariant: 'range' },
    { value: 'socMax', label: 'SOC Max', header: 'SOC Max', filterVariant: 'range' },
    { value: 'socRange', label: 'SOC Range', header: 'SOC Range', filterVariant: 'range' },
    { value: 'batteryUtilization', label: 'Battery Utilization', header: 'Battery Utilization', filterVariant: 'range' },
    { value: 'production/capacity', label: 'Production/Capacity', header: 'Production/Capacity', filterVariant: 'range' },
    { value: 'maxMismatch', label: 'Maximum Mismatch', header: 'Maximum Mismatch', filterVariant: 'range' },
    { value: '7dayCurtailment', label: '7 Day Curtailment', header: '7 Day Curtailment', filterVariant: 'range' },
    { value: 'curtailment', label: 'Curtailment', header: 'Curtailment', filterVariant: 'range' },
  ]
  return columnsConfig
}
